.category_wrapper{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-right: 30px;

  .page_creator{
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .buttons_wrapper {
    z-index: 1;

    .create_button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: lightgray;
      border-radius: 5px;
      padding: 1rem;
      max-height: 40px;
      font-weight: bold;
      cursor: pointer;
      z-index: 2;
      margin-bottom: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.create_edit_categories{
  position: absolute;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}