.section_header{
  display: flex;
  justify-content: end;
  width: 98%;
  margin: 10px auto 10px auto;

  .create_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    border-radius: 5px;
    padding: 1rem;
    max-height: 40px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.section_header_flex{
  justify-content: space-between !important;
}

.row_wrapper{
  width: 98%;
  margin: 0 auto;

  .header_row{
    .actions{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table_row{
    border: 1px solid lightgray;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    border-radius:10px;
    margin-bottom: 10px;
    background-color: white;
    cursor: pointer;
  }
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
}
