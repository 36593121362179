.page_view{
  width: 78%;
  position: absolute;
  z-index: 2;

  .categories_wrapper{
    padding: 1rem;
    width: 100%;

    .categories_field{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .edit{
      cursor: pointer;
    }

    .clip {
      color: black;
      background: lightgray !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .close{
      margin-left: 98%;
      cursor: pointer;
    }

    .create_button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: lightgray;
      border-radius: 5px;
      padding: 1rem;
      max-height: 40px;
      font-weight: bold;
      cursor: pointer;
      z-index: 2;
      width: 200px;
      margin: auto;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}