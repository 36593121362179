.form_container {
  padding: 25px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  .form_back_btn_field {
    position: fixed;
    top: 99px;
    left: 339px;
    z-index: 999;

    .form_back_btn {
      background: #d6244b;
      color: white;
    }
  }

  .testimonials_form {
    width: 50%;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 3;

    background-color: white;

    border-radius: 15px;
    padding-bottom: 30px;
    box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);

    .cancel_button {
      width: 100%;

      text-align: end;
      padding-right: 30px;
      padding-top: 20px;

      button {
        background-color: #d6244b;
        color: white;
        padding: 2px;
        z-index: 999;
      }
    }

    .field_Container {
      width: 85%;

      margin-top: 30px;

      position: relative;

      display: flex;
      justify-content: center;

      input[value=""]::-webkit-datetime-edit {
        color: transparent;
      }
      input[value=""]:focus::-webkit-datetime-edit {
        color: black !important;
      }

      .location {
        width: 100%;
        position: relative;

        .hide_suggestion {
          display: none;
        }

        .show_suggestion {
          position: absolute;
          background-color: #d7d7d7;
          width: 100%;
          z-index: 10;

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            cursor: pointer;

            li {
              padding: 10px;
              color: #4b4646;

              &:hover {
                background-color: #bfbfbf;
              }
            }
          }
        }
      }

      .image_wrapper {
        display: flex;
        flex-direction: column;
        height: 150px;
        width: 150px;
        cursor: pointer;
        z-index: 100;

        .testimonials_image_input {
          display: none;
        }

        .testimonial_image {
          height: 100%;
          width: 100%;
          overflow: hidden;

          border: 1px solid rgb(213, 209, 209);
          border-radius: 50%;
          background-color: white;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          & > svg {
            width: 40px;
            height: 40px;

            color: rgb(224, 219, 219);

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          & > img {
            height: 100%;
            width: 100%;

            -o-object-fit: contain;
            object-fit: contain;
          }

          &.testimonial_image_error{
            border: 1px solid red;
          }
        }
      }

      .array_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;

        .add_button {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;

          background-color: #d6244b;
          color: white;

          margin-bottom: 30px;
        }

        .add_button_margin{
          margin: 30px 0 0;
        }

        .array_input {
          margin-top: 30px;
        }

        .array_field {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            margin: 30px 0 0 20px;
          }
        }

        .array_field_desc {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            margin: 30px 0 0 20px;
          }

          div {
            width: 100%;
          }
        }

        .array_input {
          margin-top: 30px;
        }

        .career_array {
          margin-bottom: 30px;
        }

        .array_wrapper_image {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 50px;
          column-gap: 30px;

          .image_wrapper {
            height: auto;
            width: fit-content;

            display: flex;
            flex-direction: row;
            align-items: center;

            label {
              width: 150px;
              height: 150px;
              margin-right: 10px;
              @media (max-width: 1024px) {
                width: 100px;
                height: 100px;
              }
            }
          }

          @media (max-width: 1440px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            row-gap: 30px;
          }
          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
        }
      }
    }

    .width_100 {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 25px;
    }

    .swiper {
      width: 50%;

      .swiper-button-prev,
      .swiper-button-next {
        color: #d62449;
      }

      .swiper-pagination-bullet {
        background-color: #d62449 !important;
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 10%;
      }
    }

    .form_button {
      background-color: #d6244b;
      color: white;
      border: none;

      margin-top: 30px;
      padding: 10px 15px;
      border-radius: 7px;

      & > svg {
        margin-left: 5px;
        color: white;
      }
    }
  }

  .other_services{
    width: 100%;
    margin-top: -30px;
  }

.editor_wrapper{
  width: 100%;

  .editor_wrapper_description{
    margin: 3px 14px 0 14px;
  }

  &.editor_wrapper_error {
    >div {
      >div {
        border: 1px solid #d32f2f;
      }
    }
  }

  .editor_error{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0 14px;
    color: #d32f2f;
    }
  }
}
