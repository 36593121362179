.no_padding{
  padding: 0!important;
}
.view_field {
  padding: 25px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  .view_cancel_button{
    position: fixed;
    top: 100px;
    left: 350px;

    @media (max-width: 1200px) {
      width: 20px;
    }

    button {
      background-color: #d6244b;
      color: white;

      svg {
        @media (max-width: 1200px) {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .view_testimonial_container{
    box-shadow: none !important;
    width: 100% !important;
    margin-top: 0 !important;
    padding: 0 !important;
  }

  .view_contant {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 50%;
    border-radius: 15px;
    margin-top: 25px;
    padding: 30px 0;
    box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    z-index: 3;
    position: relative;

    .width{
      width: 93% !important;
    }

    .view_title {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .view_container {
      width: 85%;
      position: relative;

      .view_testimonial_subtitle {
        font-size: 18px !important;
      }

      .view_testimonial_personal {
        display: flex;
        flex-direction: row;

        .view_testimonial_image_filed {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          margin-right: 50px;

          .view_testimonial_image {
            width: 150px;
          }
        }

        .view_testimonial_person {
          .view_testimonial_subtitle {
            font-size: 16px !important;
            font-weight: 600;
            text-transform: capitalize;
          }

          .view_testimonial_text {
            font-size: 14px !important;
          }
        }
      }

      .view_subtitle {
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
        text-transform: capitalize;
      }
      .view_description_title{
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;
        text-transform: capitalize;
      }

      .view_description_field{
        margin-left: 10px;
      }

      .view_text {
        margin-top: 5px;
      }
      .view_image_filed {
        display: flex;
        justify-content: center;

        margin-top: 30px;

        .view_image {
          height: auto;
          width: 200px;
        }
      }
    }
  }
}