// Sidebar menu dark color scheme

.app-sidebar {

  &--dark {
    .sidebar-navigation {

      .sidebar-menu-box {
        background: rgba($black, .15);
      }

      .sidebar-header {
        color: rgba($white, .35);
      }

      ul {
        li {
          & > a {
            color: rgba($white, 1);;

            .sidebar-icon {
              & > * {
                color: $white;
              }
            }
          }

          ul {
            li {
              a {
                &::after {
                  background: rgba($black, .2);
                }

                &:hover,
                &.active {
                  &::after {
                    background: rgba($black, .5);
                  }
                }
              }
            }
          }

          & > a:hover,
          & > a.active {
            background: rgba($black, .15);

            .sidebar-icon-indicator {
              opacity: .7;
            }

            .sidebar-icon {
              & > * {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
.app-sidebar-collapsed {
  .app-sidebar--dark:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($white, .15);
          }
        }
      }
    }
  }
}
