.element_wrapper {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr 7fr 1fr;

  .element_image {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 5px;
  }

  .title_wrapper {
    padding-left: 10px;

    .title_label {
      font-size: 14px;
      font-weight: bold;
    }
    .title {
      display: flex;
      align-items: center;
    }
  }

  .description_wrapper {
    padding-left: 10px;

    .short_description {
      display: flex;
      align-items: center;
    }
    .description_label {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: end;

    .action_remove {
      .delete_icon {
        cursor: pointer;
        color: red;
        border-radius: 5px;

        &:hover {
          transform: scale(1.1);
          transition-duration: 0.5s;
        }
      }
    }

    .action_edit {
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
      }
    }

    .action_view {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 10px;

      svg {
        height: 20px;
        width: 22px;
      }

      &:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
      }
    }
  }
}
