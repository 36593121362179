.testimonial_container {
  padding: 15px;
  position: relative;

  .testimonial_contant {
    padding-top: 15px;

    .creater_component {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }

    .testimonials {
      background-color: #fafafa;
      width: 85%;

      .testimonials_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .imageField {
          margin-right: 15px;
          border-radius: 50%;
          width: 150px;
          height: 150px;

          .testimonials_image_input {
            display: none;
          }

          .testimonial_image {
            height: 100%;
            width: 100%;
            overflow: hidden;

            border: 1px solid rgb(213, 209, 209);
            border-radius: 5px;
            background-color: white;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            & > svg {
              width: 40px;
              height: 40px;

              color: rgb(224, 219, 219);

              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            & > img {
              height: 100%;
              width: 100%;

              -o-object-fit: cover;
              object-fit: cover;

              border-radius: 50%;
            }
          }
        }

        .aboutPerson {
          display: flex;
          flex-direction: column;

          .nameField {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;

            color: #000000;
          }
          .positionField {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;

            color: #5a5a5a;
          }

          .testimonials_field{
            margin: 10px 0;
          }
        }

        .edit_button {
          border: none;
          background-color: transparent;
        }
      }
      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;

        display: flex;
        flex-direction: column;

        text-transform: capitalize;
        p {
          color: #000000;
        }

        span {
          color: #acacac;
        }

        .testimonials_field{
          margin: 10px 0;
        }
      }
      .cardSaveBtn{
        border: none;
        background-color: #d6244b;
        color: white;
        padding: 5px 15px;
        border-radius: 5px;
        margin-bottom: 15px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.testimonials_form_container {
  padding: 25px 0;
  display: flex;
  justify-content: center;

  .testimonials_form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 50%;
    border-radius: 15px;
    padding-bottom: 30px;
    box-shadow: 0px 0px 9px 0px rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0px 0px 9px 0px rgba(56, 56, 56, 0.7);
    
    .field_Container {
      width: 85%;

      margin-top: 30px;

      position: relative;

      display: flex;
      justify-content: center;

      .testimonial_image {
        height: 25vh;
        width: 35%;
        overflow: hidden;

        border: 1px solid rgb(213, 209, 209);
        border-radius: 5px;
        background-color: white;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        & > svg {
          width: 100px;
          height: 100px;
          color: grey;

          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
        }

        & > img {
          width: 100%;
          object-fit: cover;
        }
      }

      .testimonials_image_input {
        display: none;
      }

      .testimonials_field {
        width: 100%;

        border: 1px solid rgb(213, 209, 209);
        border-radius: 5px;

        padding: 15px;
      }

      .testimonials_error {
        color: red !important;
        font-size: 12px !important;

        position: absolute;
        right: 7px;
        bottom: -18px;
      }
    }

    .form_button {
      background-color: #d6244b;
      color: white;
      border: none;

      margin-top: 30px;
      padding: 10px 15px;
      border-radius: 7px;

      & > svg {
        margin-left: 5px;
        color: white;
      }
    }
  }
}
