.filter{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 10px auto;
  width: 98%;

  .filter_input{
    outline: none;
    border: 1px solid lightgray;
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
  }
}